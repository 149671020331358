<template>
  <v-app>
    <SonyEnvironmentSystemBar />
    <v-app-bar app dark class="bg-black">
      <SelectedProgram v-if="!loading"></SelectedProgram>
      <SelectedProgramDropdown class="pr-15"></SelectedProgramDropdown>
      <v-spacer></v-spacer>
      <country-flag v-if="this.selectedCountry.name == 'USA'" country="usa" size="normal" />
      <country-flag v-if="this.selectedCountry.name == 'CAN'" country="can" size="normal" />
      <LocaleDropdown :routeToDashboard="false"></LocaleDropdown>
    </v-app-bar>
    <v-main class="login-bg" v-if="!loading">
      <router-view></router-view>
    </v-main>
    <v-footer absolute>
      <Footer></Footer>
    </v-footer>
  </v-app>
</template>

<script>
import Footer from "../../components/Footer.vue";
import LocaleDropdown from "../../gapp-components/components/fields/LocaleDropdown.vue";
import SelectedProgramDropdown from "../../gapp-components/components/fields/SelectedProgramDropdown.vue";
import SelectedProgram from "../../components/SelectedProgram.vue";
import SonyEnvironmentSystemBar from "../SonyEnvironmentSystemBar.vue";
import CountryFlag from "vue-country-flag";
import { mapGetters } from "vuex";

export default {
  name: "LayoutPublicCeurNoMenu",
  components: {
    CountryFlag,
    SonyEnvironmentSystemBar,
    SelectedProgram,
    SelectedProgramDropdown,
    LocaleDropdown,
    Footer
  },
  data: () => ({
    loading: true
  }),
  async mounted() {
    // clear existing session
    this.$auth.logout();

    this.loading = true;
    let programKey = this.$sonyProgram.getProgramKeyByDomain();
    await this.$api
      .get("/api/programs/byKey/" + programKey + "/public")
      .then(({ data }) => {
        let program = data;
        return Promise.all([
          this.$store.dispatch("selectProgram", program),
          this.$store.dispatch("selectClient", program.programGroup.client)
        ]);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedCountry"])
  }
};
</script>
